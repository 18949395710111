import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "query", "results", "spinner" ]

  connect() {
  }

  disconnect() {
    this.reset()
  }

  searchPress(e) {
    e.preventDefault();
    if(e.keyCode == 13) e.stopPropagation();
    this.fetchResults();
  }
  
  fetchResults() {
    this.spinnerTarget.classList.remove("d-none")
    if(this.query == "") {
      this.reset()
      return
    }

    if(this.query == this.previousQuery) {
      return
    }
    this.previousQuery = this.query;

    const url = new URL(this.element.dataset.url);
    url.searchParams.append("query", this.query);

    this.abortPreviousFetchRequest();

    this.abortController = new AbortController()
    fetch(url, { signal: this.abortController.signal })
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
        this.spinnerTarget.classList.add("d-none")
      })
      // .catch(() => {
      //   this.spinnerTarget.classList.add("d-none")
      // })
  }

  selectItem(e) {
    this.previousQuery = null;
    this.queryTarget.value = e.currentTarget.innerText;
    this.resultsTarget.innerHTML = "";
    this.spinnerTarget.classList.add("d-none")
  }

  navigateResults(event) {
    if(event.keyCode == 13) event.preventDefault();
    if(this.searchResultsController) {
      this.searchResultsController.navigateResults(event)
    }
  }

  // private

  reset() {
    this.resultsTarget.innerHTML = ""
    this.queryTarget.value = ""
    this.previousQuery = null
    this.spinnerTarget.classList.add("d-none")
  }

  abortPreviousFetchRequest() {
    if(this.abortController) {
      this.abortController.abort()
    }
  }

  get query() {
    return this.queryTarget.value
  }

  get searchResultsController() {
    return this.application.getControllerForElementAndIdentifier(this.resultsTarget.firstElementChild, "search-results")
  }
}